    <template>
    <section>
        <div class="content-wrapper">
            <div>
                <!-- <p>new Date: {{date}}</p>
                <p>date niampy: {{date3}}</p>
                <p>date 4: {{date4}}</p>
                <button @click="prc()" @>tester PRC</button> -->
            </div>
            <!-- <button @click="sendMail()">Send mail</button> -->
        </div>
    </section>
</template>

<script>
import { dateMixin } from './mixins/date'
// import axios from 'axios';
export default {
    name: 'test',
    mixins:[dateMixin],
    components: {
        },
    data () {
        return {
            date:'2022-09-17 14:30:00',
            date3:'',
            img:'',
            specialHours: {
                2: [
                    { 
                        from: 18 * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'    
                    }
                ],
                4: [
                    { 
                        from: 18 * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'
                    }
                ],
                5: [
                    { 
                        from: 18 * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'
                    }
                ],
                6: [
                    { 
                        from: 9.5 * 60, to: 10.5 * 60, class: 'closed',
                        // label: 'Fermer'
                    },
                    { 
                        from: 16 * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'
                     }
                ],
                },
            }
    },
    computed: {
        date4(){
            return new Date ("2022-03-03 10:50")
        }
    },
    methods:{
        reduceMinutesFromEndDate(){
            console.log("special Hours", this.specialHours);
            for (let day in this.specialHours) {
                console.log(this.specialHours[day]);
            }
            
        },
        getDate(date_params){
            let date = new Date (date_params)
            console.log(date.toLocaleTimeString("fr-FR"));
            console.log(date.toLocaleString("fr-FR"));
        },
        addTenMinutesToTime(date_parameter){
            let date_day = date_parameter.split(' ')[0]
            let date_time = date_parameter.split(' ')[1]
            const date = new Date(date_parameter);
            if(date_time == "23:55" || date_time =="23:50"){
                return date_parameter
            }
            if(date.getMinutes()%2 == 0){
                date.setMinutes(date.getMinutes() + 10);
            }else{
                date.setMinutes(date.getMinutes() + 5);
            }
            let time = date.toTimeString().slice(0,5)
            return date_day +' '+ time
        },
         reduceMinutes(date_parameter_with_timezone,duration){
            let hour_parameter = date_parameter_with_timezone.split(' ')[1].substring(0,5)
            let date_day = date_parameter_with_timezone.split(' ')[0]
            let date_parameter = `${date_day} ${hour_parameter}`
            const date = new Date(date_parameter);
            date.setMinutes(date.getMinutes() - (duration*60));
            let time = date.toTimeString().slice(0,5)
            console.log("reduce Minutes",date_day +' '+ time+":00");
            return date_day +' '+ time+":00"
        },
    //     prc(){
    //         axios.post('https://backend-prc.comolapapaya.com/contact',{
    //             "email":"sataboum3@gmail.com",
    //             "name":"sata",
    //             "message":"ceci est un mail de test",
    //             "phone":"+65465464565464"
    //         })
    //         .then(test=> console.log(test))
    //         .catch(err=>console.error(err))
    //     },
    //     datePlusDuration(date,duration){
    //         let time = this.GetTimeInDate(date)
    //             let hours = Number(duration.substr(0,2)) + Number(time.substr(0,2))
    //             date.setHours(hours)
    //             let minutes = Number(time.substr(3,2)) + Number(duration.substr(3,2)) 
    //             date.setMinutes(minutes)
    //             return date
    //     },
    //     GetTimeInDate(mydate){
    //         var stringdate;

    //         if (!mydate) {
    //             mydate = new Date();    
    //         }
    //         stringdate =this.digit(mydate.getHours()) + ":"  
    //             + this.digit(mydate.getMinutes())  
    //         return stringdate
    //     },
    //     digit(time){
    //         if (Number(time)< 10) {
    //             time = "0"+time
    //         }
    //         return time
    //     },
    //     getDate(){
    //         // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    //         let date = new Date()
    //         // this.date2= this.date.toLocaleDateString('fr-FR', options)
    //         this.date3= this.datePlusDuration(date, "00:20")
    //         // this.date3 = this.date.setHours(this.date.getHours())
    //     },
    //     sendMail(){
    //         axios.post(`myorders/sendmail`,{
    //             order:"01FYENPR9YV7BCYN6XZ9X15SMG"
    //         })
    //         .then(()=> {
    //             alert('Un email vous a été envoyé')
    //             })
    //         .catch(errSendmail => console.log(errSendmail))
    //     },
    //     testDate(){
    //         // // Using built-in methods
    //         // let start = new Date()

    //         // // The event to time goes here:
    //         // doSomethingForALongTime()
    //         // let end = new Date()
    //         // let elapsed = end.getTime() - start.getTime() // elapsed time in milliseconds

    //     }
    },
    mounted() {
        // this.getImg()
    //    this.getDate()
    //    this.date = new Date()
    this.reduceMinutes(this.date,0.5)
    this.reduceMinutesFromEndDate()
    }
}
</script>
<style scoped>
img{
    /* forme des images */
    border-radius: 50%;
    width: 13rem;
    height: 200px;
    object-fit: cover;
    
}
</style>